body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary-bg-color: #687E6C;
  --secondary-bg-color: #5E7862;
  --detail-primary-bg-color: #557059;
  --detail-secondary-bg-color: #5E7862;
  --primary-color: #FEF5EC;
  min-height: 100dvh;

  background-color: red;
}

.event {
  background-color: #FFF4E7;
  border-radius: 10px;
  height: 3rem;
  margin-top: 0rem;
  border: 1px solid #5E7862;
  color: #4D6951;
  padding-left: 2rem;
  padding-top: .5rem;
  font-size: 10pt;
}

.event .timeframe {
  color: #AAAAAA;
  font-size: 8pt;
}

.event.empty {
  background-color: #5E7862;
  border-radius: 10px;
  height: 3rem;
  margin-top: 0rem;
  border: 1px solid #5E7862;
  color: #4D6951;
  padding-left: 2rem;
  padding-top: .5rem;
}

input[type=search] {
  background-color: #5E7862;
  border: 1px solid #547358;
  border-radius: 12px;
  color: #FEF5EC;
  padding-left: 1rem;
  border-left: none;
}


.custom-toggler {
  background-color: #5B785F;
}

/* Ändern Sie die Farbe in der Stricheigenschaft der Bilddaten */
.custom-toggler .navbar-toggler-icon {
  background-color: #5B785F;
  background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(254, 245, 236, 0.8)' stroke-width=' 2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24' /%3E%3C/svg%3E");
}

::placeholder {
  color: #FEF5EC;
  opacity: 1;
  /* Firefox */
}

.wrapper {
  min-height: 100dvh;
  background-color: var(--primary-bg-color);
  color: #FEF5EC;
  font-family: "Gelasio";
  font-size: 16pt;
}

.header {
  justify-content: space-evenly;
}

@media only screen and (max-width: 768px) {
  .header {
    justify-content: space-between;
  }

  .wrapper {
    background-color: #557059;
  }
}

.pointer {
  cursor: pointer;
}

.edit {
  cursor: pointer;
  display: none;
}
.editRow:hover > .edit {
  display: inline;
}


/*  */

.container.detail ::placeholder {
  color: #637E67;
  font-family: "Gelasio";
  font-size: 12pt;
  font-weight: 600;
  font-style: italic;
  opacity: .7;
  /* Firefox */
}

.container.detail .custom-search::placeholder {
  color: #FEF5EC;
  font-family: "Gelasio";
  font-weight: normal;
  opacity: 1;
  /* Firefox */
}

.detail-wrapper {
  background-color: var(--detail-secondary-bg-color);
  border: 1px solid #547358;
  border-radius: 12px;
}

.foto-schedule {
  background-color: var(--detail-secondary-bg-color);
  border: 1px solid #547358;

}

.detail-wrapper .text {
  color: #B7B7B7;
  font-style: italic;
  font-size: 14pt;
}

.detail-wrapper::placeholder {
  color: #637E67;
  opacity: 1;
  /* Firefox */
}



.form-check-input:checked {
  background-color: #619669 !important;
  border: 1px solid #619669 !important;
}

.wrapper.detail {
  min-height: 100dvh;
  background-color: var(--detail-primary-bg-color);
  color: #FEF5EC;
  font-family: "Gelasio";
  font-size: 16pt;
}

.wrapper .subtext {
  color: #C3C3C3;
  font-style: italic;
  font-size: 12pt;
}

.wrapper .infobox {
  background-color: #5E7862;
  border: 1px solid #547358;
  border-radius: 10px;
}

.inactive {
  background-color: #4D6951;
  color: #7F9582;
}

.btn-secondary.active {
  background-color: #FFF4E7;
  color: #637E67;
}


.nav-link {
  color: #F1F8E8 !important;
}

.nav-link.active {
  color: #F1F8E8 !important;
}

.container.detail {
  background-color: var(--detail-primary-bg-color);
}